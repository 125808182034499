import React from "react";
import { RelatedNewsCTAProps } from "../../types/news-page";
import { Link } from "gatsby";

import {
  BiChevronsLeft,
  BiChevronLeft,
  BiChevronsRight,
  BiChevronRight,
} from "react-icons/bi";
import { composeNewsUrl } from "../../utils/helpers";

const RelatedNewsCTA: React.FC<RelatedNewsCTAProps> = ({
  nextPagePath,
  previousPagePath,
  currentPath,
  newestNews,
  oldestNews,
}: RelatedNewsCTAProps) => {
  const newestNewsPath =
    newestNews &&
    newestNews.nodes.length > 0 &&
    composeNewsUrl(newestNews.nodes[0]);
  const oldestNewsPath =
    oldestNews &&
    oldestNews.nodes.length > 0 &&
    composeNewsUrl(oldestNews.nodes[0]);
  const newestLinkDisableStatus =
    !newestNewsPath || newestNewsPath == currentPath;
  const oldestLinkDisableStatus =
    !oldestNewsPath || oldestNewsPath == currentPath;

  return (
    <div className="flex justify-between items-center [&_div]:flex [&_div]:gap-x-2 [&_a]:min-w-[32px] [&_a]:min-h-[32px] [&_a]:text-sm [&_a]:font-semibold [&_a]:rounded-[2px] [&_a]:border [&_a]:border-dsc-softgray [&_a]:flex [&_a]:justify-center [&_a]:items-center mt-[56px] [&_svg]:transition-all [&_a]:duration-500">
      <div>
        <Link
          aria-disabled={newestLinkDisableStatus}
          className={`${
            newestLinkDisableStatus ? "pointer-events-none" : ""
          } group`}
          to={newestNewsPath || "/"}
        >
          <BiChevronsLeft
            size={18}
            className="text-[#C4C4C4] group-hover:text-dsc-body"
          />
        </Link>
        <Link
          aria-disabled={!previousPagePath}
          className={`${!previousPagePath ? "pointer-events-none" : ""} group`}
          to={previousPagePath || "/"}
        >
          <BiChevronLeft
            size={18}
            className="text-[#C4C4C4] group-hover:text-dsc-body"
          />
        </Link>
      </div>
      <div>
        <Link
          aria-disabled={!nextPagePath}
          className={`${!nextPagePath ? "pointer-events-none" : ""} group`}
          to={nextPagePath || "/"}
        >
          <BiChevronRight
            size={18}
            className="text-[#C4C4C4] group-hover:text-dsc-body"
          />
        </Link>
        <Link
          aria-disabled={oldestLinkDisableStatus}
          className={`${
            oldestLinkDisableStatus ? "pointer-events-none" : ""
          } group`}
          to={oldestNewsPath || "/"}
        >
          <BiChevronsRight
            size={18}
            className="text-[#C4C4C4] group-hover:text-dsc-body"
          />
        </Link>
      </div>
    </div>
  );
};

export default RelatedNewsCTA;
