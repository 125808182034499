import { graphql } from "gatsby";
import React from "react";
import Breadcrumbs from "../../components/common/breadcrumbs/Breadcrumbs";
import Layout from "../../components/common/Layout";
import ArchiveTitle from "../../components/common/ArchiveTitle";
import { MetaInfoInterface, NewsPageInterFace } from "../../types/news-page";
import CategoryBadge from "../../components/common/CategoryBadge";
import Sidebar from "../../components/news-details/Sidebar";
import ContentArea from "../../components/news-details/ContentArea";
import Meta from "../../components/common/Meta";
import RelatedNewsCTA from "../../components/common/RelatedNews";
import Button from "../../components/common/Button";

const NewsDetailsPage: React.FC<NewsPageInterFace> = ({
  data,
  ...props
}: NewsPageInterFace) => {
  const news = data.allStrapiBlog?.nodes[0];
  const MetaInfoComponent = (
    <MetaInfo
      date={news.release_date}
      category={news.blog_categories[0].title}
    />
  );
  const meta = {
    title: news.SEO.meta_title,
    description: news.SEO.meta_description,
    pathname: props.path,
    image: `https://strapi-admin.digitalstacks.net${news.SEO.featured_image?.url}`,
  };

  return (
    <Layout>
      <Meta meta={meta} />
      <Breadcrumbs
        path={`/news/release`}
        customLastPath={props.path}
        customLastTitle={news.title}
        className="mt-4 mb-8"
      />
      <div className="px-4 xl:px-[100px]">
        <ArchiveTitle
          className="[&_h1]:text-[32px] [&_h1]:leading-[37.5px] [&_h1]:mb-4"
          title={news.title}
          shortText={MetaInfoComponent}
        />
        <div className="grid grid-cols-1 lg:grid-cols-[auto_300px] gap-x-9 gap-y-8 mb-[72px]">
          <div>
            <ContentArea content={news.body} />
            <RelatedNewsCTA
              previousPagePath={props.pageContext?.previousPagePath}
              nextPagePath={props.pageContext?.nextPagePath}
              newestNews={data.newestNews}
              oldestNews={data.oldestNews}
              currentPath={props.path}
            />
          </div>
          <div>
            {data.latestNews && (
              <Sidebar
                latestNews={data.latestNews}
                categories={data.allStrapiBlogCategory}
              />
            )}
          </div>
          <Button
            title="お知らせ一覧へ"
            path="/news/"
            className="hover:bg-dsc-white hover:border hover:border-dsc-button hover:text-dsc-button w-fit ml-auto mr-0"
            size="normal"
          />
        </div>
      </div>
    </Layout>
  );
};

export default NewsDetailsPage;

const MetaInfo: React.FC<MetaInfoInterface> = ({
  date,
  category,
}: MetaInfoInterface) => {
  return (
    <div className="flex items-center gap-x-2">
      <span className="text-sm leading-4 text-dsc-body">{date}</span>
      <CategoryBadge category={category} />
    </div>
  );
};

export const query = graphql`
  query FetchNewsDetails($pageId: String!) {
    allStrapiBlog(filter: { slug: { eq: $pageId } }) {
      nodes {
        title
        slug
        release_date(formatString: "YYYY.MM.DD ddd")
        blog_categories {
          title
        }
        SEO {
          featured_image {
            url
          }
          meta_description
          meta_title
        }
        body {
          medias {
            src
            file {
              alternativeText
            }
            localFile {
              url
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  webpOptions: { quality: 100 }
                  layout: CONSTRAINED
                )
              }
            }
          }
          data {
            body
          }
        }
      }
    }
    newestNews: allStrapiBlog(
      limit: 1
      sort: { order: DESC, fields: release_date }
    ) {
      nodes {
        slug
        blog_categories {
          slug
        }
      }
    }
    oldestNews: allStrapiBlog(
      limit: 1
      sort: { order: ASC, fields: release_date }
    ) {
      nodes {
        slug
        blog_categories {
          slug
        }
      }
    }
    allStrapiBlogCategory {
      nodes {
        title
        slug
        blogs {
          slug
        }
      }
    }
    latestNews: allStrapiBlog(
      limit: 10
      sort: { order: DESC, fields: release_date }
    ) {
      nodes {
        slug
        blog_categories {
          slug
        }
        release_date(formatString: "YYYY.MM.DD ddd")
        title
      }
    }
    allSite {
      nodes {
        siteMetadata {
          siteUrl
        }
      }
    }
  }
`;
