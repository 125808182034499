import React from "react";
import ReactMarkdown from "react-markdown";
import { componentsBuilder } from "../../utils/renderer";
import Button from "../common/Button";

const ContentArea = ({ content }) => {
  return (
    <div>
      <div className="w-full max-w-none prose prose-headings:font-bold leading-normal prose-headings:mt-10 prose-headings:mb-4 prose-headings:text-dsc-title prose-h1:text-[32px] prose-h2:text-2xl prose-h3:text-xl prose-h4:text-lg prose-p:text-base prose-p:text-dsc-body prose-headings:first:mt-0 prose-a:no-underline prose-a:text-dsc-link prose-ul:list-disc prose-ol:lining-nums prose-hr:my-6 prose-a:w-full prose-a:break-all prose-a:whitespace-pre-wrap">
        <ReactMarkdown
          components={componentsBuilder(content.medias)}
          children={content?.data?.body}
        />
      </div>
    </div>
  );
};

export default ContentArea;
