import Link from "gatsby-link";
import React from "react";
import { SidebarInterface } from "../../types/news-page";

const titleStyle =
  "text-lg leading-[26.88px] text-[#191A1B] tracking-[0.5px] font-main font-bold";

const Sidebar: React.FC<SidebarInterface> = ({ latestNews, categories }) => {
  return (
    <div>
      <h2 className={`${titleStyle}  mb-6`}>Latest News</h2>
      <div className="flex flex-col gap-y-2 mb-10">
        {latestNews.nodes.map((news, index) => (
          <Link
            to={`/${news.blog_categories[0].slug}/${news.slug}/`}
            className="grid grid-cols-[105px_auto] lg:grid-cols-[105px_190px] gap-x-2 text-sm leading-4 text-dsc-body hover:text-dsc-title"
            key={index}
          >
            <p className="whitespace-nowrap">{news.release_date}</p>
            <p className="line-clamp-1">{news.title}</p>
          </Link>
        ))}
      </div>
      <h2 className={`${titleStyle} mb-4`}>Categories</h2>
      <div className="flex flex-col gap-y-2 mb-10">
        {categories.nodes.map((category, index) => (
          <Link
            to={`/${category.slug}/`}
            className="flex gap-x-1 text-sm leading-4 text-dsc-body hover:text-dsc-title"
            key={index}
          >
            <span>{category.title}</span>
            {category.blogs && <span>({category.blogs.length})</span>}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
